import { default as useCommerceBasket } from '$features/commerce-api/hooks/useBasket';
import { useCallback } from 'react';
import create from 'zustand';
import { Basket, Product, ProductItemBasket, Variant } from '~/lib/data-contract/salesforce';
import { useEvents } from '~/shared/hooks/useEvents';
import { _transformProductToDataLayer } from '~/shared/hooks/useEvents/helpers';
import { ProductEventData } from '~/shared/hooks/useEvents/models';
import { useFrame } from '~/shared/utils';
import { useErrorHandler } from '~/shared/utils/errorBoundary/hooks/useErrorHandler';
import { useTranslation } from '~/shared/utils/translation';
import { useBasketState } from './useBasketState';
import { _matchCouponsFromCartWithProducts } from '~/shared/hooks/useEvents/useGTMEvents/helper';
import { notifyError } from '~/shared/utils/errorBoundary/toast';
import { TranslationKey } from '~/lib';

interface BasketStore {
    basket: Basket | undefined;
    setBasket: (basket: Basket) => void;
}

type ErrorResponse = {
    type: string;
    message: string;
};

enum ErrorType {
    SOLD_OUT = 'ProductItemNotAvailableException',
    NOT_FOUND = 'InvalidProductItemException',
    BASKET_FULL = 'BasketQuotaExceededException',
}

const isErrorResponse = (error: any): error is ErrorResponse => {
    return error && typeof error.type === 'string' && typeof error.message === 'string';
};

export enum QuantityChangeType {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}
export const useBasketStore = create<BasketStore>((set) => ({
    basket: undefined,
    setBasket: (basket: any) => set({ basket }),
}));
/**
 * Exposes basket data and actions.
 */
export const useBasket = (opts?: Record<string, any>) => {
    const uIState = useBasketState();
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { addToBasketEvent, removeFromBasketEvent } = useEvents(frame);
    const { trackAndDisplayError: trackError } = useErrorHandler();
    /**
     * Generic error handler
     */
    const handleBasketErrors = useCallback((fn: any) => {
        return async (...args: any[]) => {
            try {
                await fn.call(null, ...args);
            } catch (e: any) {
                trackError(e);
            }
        };
    }, []);

    const extractCustomProperties = useCallback((item: Record<string, string>) => {
        const properties: Record<string, string> = Object.keys(item).reduce((collection, key) => {
            // Custom keys in Salesforce start with 'c_'.
            if (key.startsWith('c_')) {
                return {
                    ...collection,
                    [key]: item[key],
                };
            }

            return collection;
        }, {});

        return properties;
    }, []);

    const {
        addItemToBasket: baseAddItemToBasket,
        removeItemFromBasket: baseRemoveItemFromBasket,
        updateItemInBasket: baseUpdateItemInBasket,
        approvePaymentInstrument: baseApprovePaymentInstrument,
        loaded,
        ...options
    } = useCommerceBasket({ currency: opts?.currency ?? frame?.market?.currency?.currency });

    const getProductWithCouponApplied = (
        basket: Basket | undefined,
        product: Product | ProductEventData,
    ) => {
        if (!basket) return product;
        const couponCodes = _matchCouponsFromCartWithProducts(
            basket?.productItems,
            basket?.couponItems,
        );

        return {
            ...product,
            coupon: couponCodes?.appliedCoupons[product.id]?.couponCode,
        };
    };

    const increaseItemInBasket = async (product: Product) => {
        if (product.quantity >= product?.c_availableStock) return;

        try {
            const result = await baseUpdateItemInBasket(
                { productId: product.id, quantity: product.quantity + 1 },
                product.itemId,
            );

            const productWithCouponApplied = getProductWithCouponApplied(result, product);
            addToBasketEvent(_transformProductToDataLayer(productWithCouponApplied, 1));
            return result;
        } catch (e) {
            notifyError(new Error(translate('basket.addToBasketError')));
        }
    };

    const decreaseItemInBasket = async (product: Product) => {
        try {
            const result = await baseUpdateItemInBasket(
                { productId: product.id, quantity: product.quantity - 1 },
                product.itemId,
            );

            const productWithCouponApplied = getProductWithCouponApplied(result, product);
            removeFromBasketEvent(_transformProductToDataLayer(productWithCouponApplied, 1));
            return result;
        } catch (e) {
            throw new Error(translate('basket.removeFromBasketError'));
        }
    };

    const updateItemInBasket = async (
        product: Product,
        newQuantity: number,
        quantityDifference: number,
        type: string,
    ) => {
        try {
            const result = await baseUpdateItemInBasket(
                { productId: product.id, quantity: newQuantity },
                product.itemId,
            );
            const productWithCouponApplied = getProductWithCouponApplied(result, product);
            const basketEvent =
                type === QuantityChangeType.ADD ? addToBasketEvent : removeFromBasketEvent;

            basketEvent(_transformProductToDataLayer(productWithCouponApplied, quantityDifference));
        } catch (e) {
            notifyError(new Error(translate('basket.addToBasketError')));
        }
    };

    const addItemToBasket = async (
        product: ProductEventData,
        variant: Variant,
        newQuantity: number,
    ) => {
        if (!variant?.orderable || !newQuantity || !variant.productId) return;

        const productItems: ProductItemBasket[] = [
            {
                productId: variant.productId,
                quantity: newQuantity,
                ...extractCustomProperties(variant),
            },
        ];

        try {
            const result = await baseAddItemToBasket(productItems);

            const couponCodes = _matchCouponsFromCartWithProducts(
                result?.productItems,
                result?.couponItems,
            );

            const productWithCouponApplied = {
                ...product,
                coupon: couponCodes?.appliedCoupons[product.id]?.couponCode,
            };

            addToBasketEvent(getTrackingProduct(productWithCouponApplied, newQuantity));
            uIState.setAddedProductId(variant.productId);
        } catch (error) {
            if (isErrorResponse(error)) {
                let errorMessage: TranslationKey;

                switch (error.type) {
                    case ErrorType.BASKET_FULL:
                        errorMessage = 'basket.basketIsFull';
                        break;
                    case ErrorType.NOT_FOUND:
                        errorMessage = 'basket.itemNotFound';
                        break;
                    case ErrorType.SOLD_OUT:
                        errorMessage = 'basket.itemSoldOut';
                        break;
                    default:
                        errorMessage = 'basket.addToBasketError';
                }

                notifyError(new Error(translate(errorMessage)));
            }
            uIState.toggleMiniBasket(false);
        }
    };

    const removeItemFromBasket = async (
        itemId: string,
        product: ProductEventData,
        newQuantity: number,
    ) => {
        try {
            await baseRemoveItemFromBasket(itemId);
            removeFromBasketEvent(getTrackingProduct(product, newQuantity));
        } catch (e) {
            throw new Error(translate('basket.removeFromBasketError'));
        }
    };

    const updatePaymentInstrument = async (
        orderNo: string,
        paymentInstrumentId: string,
        paymentMethodId: string,
    ) => {
        try {
            const order = await baseApprovePaymentInstrument(
                orderNo,
                paymentInstrumentId,
                paymentMethodId,
            );
            if (order?._flash?.length) {
                trackError(
                    'PaymentError: ' +
                        `${(order?._flash[0]?.details?.message, order?._flash[0]?.details)}`,
                );
            }
            return order;
        } catch (e) {
            trackError(e, e);
            throw new Error(translate('basket.removeFromBasketError'));
        }
    };

    const getTrackingProduct = (
        productEventData: ProductEventData,
        quantity: number,
        trackQuantity?: number,
    ) =>
        productEventData.price
            ? {
                  ...productEventData,
                  // Getting product unite price
                  price: parseFloat((productEventData.price / quantity).toFixed(2)),
                  quantity: trackQuantity ?? quantity,
              }
            : {
                  ...productEventData,
                  quantity: trackQuantity ?? quantity,
              };

    return {
        handleBasketErrors,
        updatePaymentInstrument,
        updateItemInBasket,
        removeItemFromBasket,
        addItemToBasket,
        increaseItemInBasket,
        decreaseItemInBasket,
        baseAddItemToBasket,
        ...options,
        ...uIState,
        loaded,
    };
};
