import { Product } from '~/lib/data-contract/salesforce';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { PriceTypes, PromoTypes, TPrice } from '~/shared/components/ProductCard/components/Price';
import { ProductEventData } from './models';

export const _transformProductDetailsToDataLayer = (
    hit: TProductDetails,
    quantity: number,
    position: number,
    isPlusMember?: boolean
): ProductEventData => {
    if (!hit) return {} as ProductEventData;
    const prices = hit?.cust_prices ?? hit.c_prices;
    return {
        brand: hit?.brand?.text ?? ((hit.brand as unknown) as string), //issue with multiple types
        category: hit?.primary_category_id ?? '',
        category2: hit?.cust_categories?.[1]?.name ?? '',
        category3: hit?.cust_categories?.[2]?.name ?? '',
        category4: hit?.cust_categories?.[3]?.name ?? '',
        category5: hit?.cust_categories?.[4]?.name ?? '',
        color: hit?.cust_vendor_color ?? '',
        ean: hit?.EAN ?? '',
        freightGroup: hit?.cust_freight_group ?? '',
        fullfillment: hit?.cust_fulfillment ?? '',
        id: hit?.id ?? '',
        name: hit?.longName ?? hit?.name,
        position,
        price: getEffectivePrice(prices, isPlusMember),
        quantity: quantity ?? hit?.quantity,
        queryId: hit?.__queryID ?? '',
        rating: hit?.rating?.rating.toString() ?? '',
        ratingCount: hit?.cust_yotpoReviewCount,
        ratingValue: hit?.cust_yotpoAverageRating,
        serie: hit?.cust_series ?? '',
        variant: hit?.cust_synopsis ?? '',
        c_prices: hit?.c_prices,
        cust_prices: hit?.cust_prices,
    };
};

export const _transformProductToDataLayer = (
    hit: Product,
    quantity?: number,
    position?: number,
    isPlusMember?: boolean
): ProductEventData => {
    if (!hit) return {} as ProductEventData;
    const prices = hit?.cust_prices ?? hit.c_prices;

    return {
        brand: hit.brand ?? '',
        category: hit.c_ProductMainCategory ?? '',
        category2: hit.c_ProductGoogleCategoryName?.split(' > ')[1] ?? '',
        category3: hit.c_ProductGoogleCategoryName?.split(' > ')[2] ?? '',
        category4: hit.c_ProductGoogleCategoryName?.split(' > ')[3] ?? '',
        category5: hit.c_ProductGoogleCategoryName?.split(' > ')[4] ?? '',
        color: hit.c_ItemSupplierColour ?? '',
        ean: hit.ean ?? '',
        freightGroup: hit.c_ProductFreightGroup ?? '',
        fullfillment: hit.c_ItemFullfillment ?? '',
        id: hit.id ?? '',
        name: hit.longName ?? hit.name,
        position: position ?? 0,
        price: getEffectivePrice(prices, isPlusMember),
        quantity: quantity ?? hit.quantity,
        queryId: hit.__queryID ?? '',
        rating: hit.rating?.value.toString() ?? '',
        ratingCount: hit?.c_yotpoReviewCount ?? 0,
        ratingValue: hit?.c_yotpoAverageRating ?? 0,
        serie: hit.c_ProductSeries ?? '',
        variant: hit.c_synopsis ?? '',
        c_prices: hit?.c_prices,
        cust_prices: hit?.cust_prices,
        coupon: hit.coupon ?? '',
        stockStatus: hit?.c_in_stock_web,
    };
};

export function getEffectivePrice(hit?: TPrice[], isPlusMember?: boolean) {
    const salePrice = hit?.find((p) => p.type === PriceTypes.SALE);
    const defaultPrice = hit?.find((p) => p.type === PriceTypes.DEFAULT);

    if (salePrice) {
        if (salePrice.promotype === PromoTypes.MEMBER && !isPlusMember) {
            return priceWithoutVAT(defaultPrice?.value);
        }
        return priceWithoutVAT(salePrice.value);
    }

    return priceWithoutVAT(defaultPrice?.value);
}

export function priceWithoutVAT(price: number | string | undefined, vat = 1.25) {
    if (!price) return 0;

    return Number((Number(price) / vat).toFixed(2));
}
