import CryptoJS from 'crypto-js';

export enum TrackingCookie {
    GA = '_ga',
    FBP = '_fbp',
    FBC = '_fbc',
    GCLAU = '_gcl_au',
    GCLAW = '_gcl_aw',
    GCLID = '_gcl_gclid',
}

export type Props = {
    key: string;
    wildcardSearch?: boolean;
    regex?: RegExp;
};

export function getTrackingCookieValue({
    key,
    wildcardSearch,
    regex,
}: Props): string | string[] | null {
    let cookieValue: string | null = null;

    for (const cookie of document.cookie.split(';')) {
        const [cookieKey, value]: string[] = cookie.trim().split('=');

        // If wildcardSearch is false or undefined, use equality check. Otherwise, use startsWith.
        const keyMatches = wildcardSearch ? cookieKey.startsWith(key) : cookieKey === key;

        if (keyMatches) {
            cookieValue = value;
            break; // stop the loop once the key is found
        }
    }

    if (!cookieValue) {
        console.warn(`Cookie value for ${key} not found.`);
        return null;
    }

    // Use the provided regex to filter the cookie value, if it exists
    if (regex) {
        const match = cookieValue.match(regex);
        return match ? match[0] : null;
    }

    return cookieValue; // return the whole value if no regex is provided
}

export function getRandomTrackingValue(): string {
    const randomValue1 = Math.floor(1000000000 + Math.random() * 9000000000);
    return `${randomValue1}`;
}

// returned hashed value
export function serializeUserAgentWithTimestamp(): string {
    const userAgent = navigator.userAgent;
    const timestamp = new Date().getTime();
    const valueToHash = `${userAgent}-${timestamp}`;
    const hash = CryptoJS.SHA256(valueToHash);
    return hash.toString(CryptoJS.enc.Hex);
}
