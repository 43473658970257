import { useVariationParams } from './use-variation-params';
import { Product } from '~/lib/data-contract/salesforce';

interface ImageGroup {
    supplierColour: string;
}

interface VariationValue {
    value: string;
}

interface VariationAttribute {
    id: string;
    values: VariationValue[];
}

/**
 * This module decorates product variation attributes with additional information.
 *
 * Flow:
 * 1. Call `getVariationAttributes` to start the decoration process.
 * 2. For each variation attribute, call `decorateVariationAttribute` to:
 *    a. Determine the selected value using `getSelectedValue`.
 *    b. For each value of the variation attribute, enhance it with `getDecoratedValue`.
 *       i. The `getDecoratedValue` will:
 *          - Construct a URL for the variation using `buildVariantValueHref`.
 *          - Check if the variation is orderable with `isVariantValueOrderable`.
 *          - Find the relevant swatch image using `getVariantValueSwatch`.
 * 3. Return a list of decorated variation attributes.
 */

/**
 * Return the first image in the `swatch` type image group for a given
 * variation value of a product.
 */
const getVariantValueSwatch = (
    product: Product,
    variationValue: VariationValue
): ImageGroup | undefined => {
    const { c_swatchImages = [] } = product;
    const imageGroup = c_swatchImages.filter(
        ({ supplierColour }: any) => supplierColour === variationValue.value
    );
    return imageGroup[0];
};

/**
 * Determine if a product's variant attribute value is orderable.
 */
const isVariantValueOrderable = (product: Product, variationParams: Record<string, string>) => {
    return product?.c_variants
        ?.filter(({ variationValues }) =>
            Object.keys(variationParams).every(
                (key) => variationValues?.[key] === variationParams[key]
            )
        )
        .some(({ orderable }) => orderable);
};

/**
 * Get the selected value of a variation attribute based on matching variation parameters.
 */
const getSelectedValue = (
    variationAttribute: VariationAttribute,
    variationParams: Record<string, string>
): { name?: string; value: string } => {
    const matchedValue = variationAttribute.values.find(
        ({ value }) => value === variationParams[variationAttribute.id]
    );
    return {
        name: matchedValue?.value,
        value: variationParams[variationAttribute.id],
    };
};

/**
 * Decorate a variation value with additional properties.
 */
const getDecoratedValue = (
    value: VariationValue,
    variationAttribute: VariationAttribute,
    variationParams: Record<string, string>,
    product: Product
): Record<string, any> => {
    const params = {
        ...variationParams,
        [variationAttribute.id]: value.value,
    };

    const variant = product?.c_variants?.find((variant) =>
        Object.keys(params).every((key) => variant?.variationValues?.[key] === params[key])
    );

    return {
        ...value,
        image: getVariantValueSwatch(product, value),
        orderable: isVariantValueOrderable(product, params),
        id: variant?.productId,
        href: variant?.c_pageURL,
    };
};

/**
 * Decorate a variation attribute with a selected value and decorated values.
 */
const decorateVariationAttribute = (
    variationAttribute: VariationAttribute,
    variationParams: Record<string, string>,
    product: Product
): Record<string, any> => {
    return {
        ...variationAttribute,
        selectedValue: getSelectedValue(variationAttribute, variationParams),
        values: variationAttribute.values.map((value) =>
            getDecoratedValue(value, variationAttribute, variationParams, product)
        ),
    };
};

/**
 * Get a list of decorated variation attributes for a product.
 */
export const getVariationAttributes = (product: Product): Record<string, any>[] => {
    const { variationAttributes = [] } = product;
    const variationParams = useVariationParams(product);

    return variationAttributes.map((variationAttribute) => {
        // Ensure that values exist or default to an empty array
        const ensuredVariationAttribute = {
            ...variationAttribute,
            values: variationAttribute.values ?? [],
        };
        return decorateVariationAttribute(ensuredVariationAttribute, variationParams, product);
    });
};
