import client from 'search-insights';
import { ALGOLIA_APP, ALGOLIA_TOKEN } from '~/lib/algolia';
import { useConfig } from '../useConfig';
import { useTrackingStore } from './trackingStore';

export const useAlgoliaEvents = () => {
    const { config } = useConfig();
    const {
        isTracking: hasInit,
        setIsTracking: setHasInit,
        setUserToken,
        userToken,
    } = useTrackingStore();
    const index = config?.NEXT_PUBLIC_ALGOLIA_INDEX;

    function init(userId?: string) {
        if (!index) {
            return;
        }

        client?.('init', {
            appId: ALGOLIA_APP,
            apiKey: ALGOLIA_TOKEN,
            userToken: userId,
        });
        setHasInit(true);
        setUserToken(userId);
    }

    function onUserChange(userId: string) {
        if (!hasInit) return;
        client?.('setUserToken', userId);
        setUserToken(userId);
    }

    function productClicked(productId: string, position?: number, searchId?: string) {
        if (!hasInit) return;
        const event = 'Product Clicked';
        if (searchId) {
            client?.('clickedObjectIDsAfterSearch', {
                eventName: event,
                index: index,
                objectIDs: [productId],
                // The position in this position will be absolute regardless of the current page
                // Position start at 0, so we need to increment 1 value in it
                positions: position ? [position + 1] : [1],
                queryID: searchId,
                userToken: userToken,
            });
        } else {
            client?.('clickedObjectIDs', {
                eventName: event,
                index: index,
                objectIDs: [productId],
                userToken: userToken,
            });
        }
    }

    function productAddedToBasket(productIds: string[], searchId?: string) {
        if (!hasInit) return;
        const event = 'Product Added to Cart';
        if (searchId) {
            client?.('convertedObjectIDsAfterSearch', {
                eventName: event,
                index: index,
                objectIDs: productIds,
                queryID: searchId,
                userToken: userToken,
            });
        } else {
            client?.('convertedObjectIDs', {
                eventName: event,
                index: index,
                objectIDs: productIds,
                userToken: userToken,
            });
        }
    }

    function productPageViews(productIds: string[]) {
        if (!hasInit) return;
        client?.('viewedObjectIDs', {
            eventName: 'Product Detail Page Viewed',
            index: index,
            objectIDs: productIds,
            userToken: userToken,
        });
    }

    return {
        aiOnInit: init,
        aiOnUserChange: onUserChange,
        aiProductClicked: productClicked,
        aiProductPageView: productPageViews,
        aiProductAddedToBasket: productAddedToBasket,
    };
};
