import produce from 'immer';
import create from 'zustand';
import { ImageGroup, Product } from '~/lib/data-contract/salesforce';
import { findImageGroupBy } from '~/utils/image-group-utils';
import { useVariant } from './use-variant';
import { getVariationAttributes } from './use-variation-attributes';
import { formatVariantOptions } from '../pdp-utils';

type ProductDetailsStore = {
    isGalleryOpen?: boolean;
    selectedImageIndex?: number;
    selectedAttributes: null | Record<string, string | number>;
    variant?: Variant;
    productId?: string;
    pickedAttribute?: string;
    product?: Product;
    variationAttributes?: any;
};

type Variant = {
    _type: string;
    link: string;
    orderable: boolean;
    price: number;
    productId: string;
    variationValues: Record<string, string | number>;
};

export const useProductDetailsStore = create<ProductDetailsStore>(() => ({
    isGalleryOpen: false,
    selectedAttributes: null,
    productId: '',
    pickedAttribute: '',
}));

export const toggleGallery = (selectedImageIndex: number) => {
    useProductDetailsStore.setState((prevState) => ({
        isGalleryOpen: !prevState.isGalleryOpen,
        selectedImageIndex: selectedImageIndex,
    }));
};

export const setProduct = (product: Product) => {
    const variant = useVariant(product.variants, product.variationValues);
    setVariantAttributes(product);
    useProductDetailsStore.setState({ product, variant });
};

export const getProduct = () => {
    return useProductDetailsStore((state) => state.product);
};

export const setVariantAttributes = (product: Product) => {
    const variationAttributes = getVariationAttributes(product);
    const formattedVariantOptions = variationAttributes.map((variant) =>
        formatVariantOptions(
            variant,
            product?.c_variants as any[],
            product?.variationAttributes,
            variant.id,
        ),
    );

    useProductDetailsStore.setState({
        variationAttributes: formattedVariantOptions,
    });
};

export const getVariantSummary = (product: Product) => {
    const variationAttributes = getVariationAttributes(product);
    const hasSizeSelector = !!variationAttributes.find((attribute) => attribute.id === 'size');
    const supplierColorCount = variationAttributes.find(
        (attribute) => attribute.id === 'supplierColour',
    )?.values?.length;

    return { supplierColorCount, hasSizeSelector };
};

export const getSelectedAttributes = () => {
    return useProductDetailsStore((state) => state.selectedAttributes);
};

export const selectVariant = (variant: any) => {
    useProductDetailsStore.setState(
        produce((state) => {
            state.product.variationValues[variant.name] = variant.value;
            state.pickedAttribute = variant.name;
        }),
    );
};

export const setVariant = ({ variant }: any) => {
    useProductDetailsStore.setState({ variant });
};

export const resetSelectedAttributes = () => {
    useProductDetailsStore.setState({
        selectedAttributes: null,
        isGalleryOpen: false,
        selectedImageIndex: 0,
    });
};

export const getImages = () => {
    const { product } = useProductDetailsStore((state) => ({ product: state.product }));

    const imageGroup: ImageGroup | any = findImageGroupBy(product?.imageGroups, {
        viewType: 'large',
        selectedVariationAttributes: product?.variationValues ?? {},
    });

    return imageGroup?.images?.map((image: any) => ({ ...image, src: image.link }));
};
