import create from 'zustand';
import { BasketUIState } from '../models';

type UseBasketState = BasketUIState & {
    setAddedProductId: (state: string) => void;
    addedProductId: string;
};

export const useBasketState = create<UseBasketState>((set) => ({
    isMiniBasketOpen: false,
    toggleMiniBasket: (open) => {
        set(() => ({
            isMiniBasketOpen: open,
        }));
    },
    setAddedProductId: (state) => {
        set(() => ({
            addedProductId: state,
        }));
    },
    addedProductId: '',
}));
