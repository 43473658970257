import Head from 'next/head';
import { useMemo } from 'react';
import { useProduct } from '~/features/product-details-page/hooks/usePDPStore';
import { Breadcrumb } from '~/lib';
import { cloudflareLoader } from '~/shared/components/Image/loaders/cloudflareLoader';
import { TPrice } from '~/shared/components/ProductCard/components/Price';
import { useConfig } from '~/shared/hooks';
import { getSummeryImage } from '~/utils/image-group-utils';

export function getPrice(prices?: TPrice[]) {
    return prices?.find((x) => x.schema);
}

export const ProductSchema = ({ breadcrumb }: { breadcrumb?: Breadcrumb }) => {
    const { product } = useProduct();
    const price = getPrice(product?.c_prices);
    const image = product && getSummeryImage(product);
    const { config } = useConfig();

    const getAvailabilityLink = () => {
        if (product?.c_in_stock) return 'http://schema.org/InStock';
        if (product?.c_in_stock_store) return 'http://schema.org/InStoreOnly';
        return 'http://schema.org/OutOfStock';
    };

    const cleanJSON = (text: string | undefined) => {
        return text?.replace(/["]/g, '\\"');
    };

    const breadcrumbList =
        breadcrumb?.items?.map((item, index) => {
            return {
                '@type': 'ListItem',
                position: index + 1,
                name: item.title,
                item: config.NEXT_PUBLIC_URL + item.url,
            };
        }) ?? [];

    const productRating = product?.c_yotpoReviewCount
        ? {
              aggregateRating: {
                  '@type': 'AggregateRating',
                  ratingValue: product?.c_yotpoAverageRating,
                  ratingCount: product?.c_yotpoReviewCount,
              },
          }
        : {};

    const productImage = image ? { image: cloudflareLoader({ src: image?.link, width: 720 }) } : {};

    const productGroup = useMemo(() => {
        const variesBy = [];

        if (product?.variationValues?.supplierColour) {
            variesBy.push('https://schema.org/color');
        }

        if (product?.variationValues?.size) {
            variesBy.push('https://schema.org/size');
        }

        return {
            productGroupID: product?.master?.masterId,
            variesBy,
            material: product?.c_material,
            hasVariant: product?.c_variants?.map((variant) => {
                const color = variant?.variationValues?.supplierColour || '';
                const size = variant?.variationValues?.size || '';

                const colorVariantText = color ? ' - ' + color : '';
                const sizeVariantText = size ? ' - ' + size : '';

                const variantDetails = product.imageGroups?.find((item) => {
                    if (item.viewType === 'large') {
                        if (color && size) {
                            return (
                                item.variationAttributes?.[0]?.values?.[0].value === color &&
                                item.variationAttributes?.[1]?.values?.[0].value === size
                            );
                        } else if (color) {
                            return item.variationAttributes?.[0]?.values?.[0].value === color;
                        } else if (size) {
                            return item.variationAttributes?.[1]?.values?.[0].value === size;
                        }
                    }

                    return false;
                });

                return {
                    '@type': 'Product',
                    sku: variant.productId,
                    image: variantDetails?.images[0].link,
                    name: `${variantDetails?.images[0].title}${colorVariantText}${sizeVariantText}`,
                    description: variantDetails?.images[0].alt,
                    color,
                    size,
                    offers: {
                        '@type': 'Offer',
                        url: config.NEXT_PUBLIC_URL + variant.c_pageURL,
                        priceCurrency: 'DKK',
                        price: variant.price,
                        itemCondition: 'https://schema.org/NewCondition',
                        availability: variant.orderable
                            ? 'https://schema.org/InStock'
                            : 'https://schema.org/OutOfStock',
                        shippingDetails: { '@id': '#shipping_policy' },
                    },
                };
            }),
        };
    }, [product]);

    const hasVariants = (product?.variants?.length || 1) > 1;

    const schemaObject = useMemo(() => {
        return [
            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbList,
            },
            {
                '@context': 'http://schema.org',
                '@type': hasVariants ? 'ProductGroup' : 'Product',
                productID: product?.id,
                sku: product?.ean,
                name: cleanJSON(product?.name),
                url: config.NEXT_PUBLIC_URL + product?.c_pageURL,
                brand: cleanJSON(product?.brand),
                category: product?.c_ProductMainCategory,
                description:
                    cleanJSON(product?.longDescription) ||
                    cleanJSON(product?.shortDescription) ||
                    '',
                ...productRating,
                ...productImage,
                ...(hasVariants ? productGroup : {}),
                offers: {
                    '@type': 'Offer',
                    availability: getAvailabilityLink(),
                    price: price?.value,
                    priceCurrency: product?.currency,
                    url: config.NEXT_PUBLIC_URL + product?.c_pageURL,
                    shippingDetails: { '@id': '#shipping_policy' },
                },
            },
            {
                '@context': 'https://schema.org/',
                '@type': 'OfferShippingDetails',
                '@id': '#shipping_policy',
                shippingRate: {
                    '@type': 'MonetaryAmount',
                    value: product?.c_minimumShippingCost,
                    currency: 'DKK',
                },
                shippingDestination: {
                    '@type': 'DefinedRegion',
                    value: 'DK',
                },
            },
        ];
    }, [product]);

    // https://www.hillwebcreations.com/valid-image-size-json-ld-snippet-markup/#:~:text=%E2%80%9CImages%20must%20be%20at%20least,most%201920%C3%971080%20pixels.
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemaObject),
                }}
            >
                {}
            </script>
        </Head>
    );
};
