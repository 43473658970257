import create from 'zustand';
import { Customer } from '~/lib/data-contract/salesforce';
import { AuthTypes } from './useCustomer';

type CustomerStore = {
    customer: Customer | undefined;
    isRegistered: boolean;
    isGuest: boolean;
    isPlusMember: boolean;
    isStorePersonnel: boolean;
    isNotPlusMember: boolean;
    isInitialized: boolean;
};

export const useCustomerStore = create<CustomerStore>(() => ({
    customer: undefined,
    isRegistered: false,
    isGuest: false,
    isPlusMember: false,
    isStorePersonnel: false,
    isNotPlusMember: false,
    isInitialized: false,
}));

export const setCustomer = (customer: Customer) => {
    useCustomerStore.setState({
        customer,
        isInitialized: !!customer?.customerId,
        isRegistered: customer?.authType === AuthTypes.REGISTERED,
        isGuest: customer?.authType === AuthTypes.GUEST,
        isPlusMember: customer?.authType === AuthTypes.REGISTERED && customer?.c_isMemberPlus,
        isStorePersonnel:
            customer?.authType === AuthTypes.REGISTERED && customer?.c_isStorePersonnel,
        isNotPlusMember: customer?.authType === AuthTypes.REGISTERED && !customer?.c_isMemberPlus,
    });
};
