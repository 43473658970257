export enum GTMEvent {
    AddPaymentInfo = 'add_payment_info',
    AddShippingInfo = 'add_shipping_info',
    AddToCart = 'add_to_cart',
    BeginCheckout = 'begin_checkout',
    ViewItemList = 'view_item_list',
    Purchase = 'purchase',
    RemoveFromCart = 'remove_from_cart',
    SelectItem = 'select_item',
    ViewCart = 'view_cart',
    ViewItem = 'view_item',
    AddCoupon = 'add_coupon',
    RemoveCoupon = 'remove_coupon',
    AddGiftCard = 'add_gift_card',
    RemoveGiftCard = 'remove_gift_card',
    OverlaySearchResults = 'overlay_search_results',
}

export type GTMProductEventModel = {
    index?: number;
    item_brand: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_color: string;
    item_freight_group: string;
    item_id: string;
    item_name: string;
    price: number;
    item_product_id: string;
    item_rating?: string;
    item_ratingCount?: number;
    item_ratingValue?: number;
    item_serie: string;
    item_variant: string;
    item_warehouse_type: string;
    quantity?: number;
    stockStatus?: boolean;
};

export type GTMEcommerceEventModel = {
    affiliation?: string;
    coupon?: string;
    currency?: string;
    discount?: string;
    item_list_id?: string;
    item_list_name?: string;
    items: GTMProductEventModel[];
    payment_type?: string;
    shipping_tier?: string;
    shipping?: string;
    tax?: string;
    transaction_affiliation?: string;
    transaction_id?: string;
    value?: string;
};

export type GTMEventModel = {
    event: GTMEvent;
    ecommerce: GTMEcommerceEventModel;
};

export type GTMBasketItemModel = {
    item: string;
    quantity: string;
    price: string;
    unique_id: string;
};

export type GTMOverlaySearchModel = {
    search_term: string;
    number_of_results?: number;
    search_url: string;
    destination_url?: string;
    type_of_result?:
        | 'Product'
        | 'Search suggestion'
        | 'See all products'
        | 'Popular searches'
        | 'Other shortcuts';
    product_id?: number;
    product_name?: string;
    product_ean?: number;
    product_brand?: string;
    product_full_name?: string;
};

export type PushToLayerModel =
    | ({
          eventCallback?: () => void;
      } & {
          [key: string]: any;
      })
    | GTMEventModel;
