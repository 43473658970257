import { reactPlugin } from '../appInsights';
import { notifyError } from '../toast';

export const useErrorHandler = () => {
    function createError(error: any) {
        return error instanceof Error ? error : new Error(error);
    }
    function trackError(innerError: any, err: Error) {
        try {
            reactPlugin.trackException({ exception: innerError ?? err });
        } catch (e) {
            console.error(e);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackAndDisplayError = (error: any | Error, innerError?: any | Error) => {
        const err = createError(error);

        notifyError(err);
        trackError(innerError, err);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackOnlyError = (error: any | Error, innerError?: any | Error) => {
        const err = createError(error);
        trackError(innerError, err);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayOnlyError = (error: any | Error) => {
        const err = createError(error);
        notifyError(err);
    };

    return { trackAndDisplayError, trackOnlyError, displayOnlyError };
};
