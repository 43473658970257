import create from 'zustand';

export type TrackingStore = {
    isTracking: boolean;
    userToken?: string;
    setUserToken: (ut?: string) => void;
    setIsTracking: (res: boolean) => void;
};

export const useTrackingStore = create<TrackingStore>((set) => ({
    isTracking: false,
    userToken: undefined,
    setIsTracking: (res: boolean) => set({ isTracking: res }),
    setUserToken: (ut?: string) => set({ userToken: ut }),
}));
