import create from 'zustand';
import { EventIdentifier } from './useEvents';

export type EventStoreQueueType = () => Promise<void> | void;
export type CheckoutStep = 1 | 2 | 3 | 4;

export type EventStoreType = {
    isReady: boolean;
    list: EventIdentifier;
    queue: EventStoreQueueType[];
};

export const useEventStore = create<EventStoreType>(() => ({
    isReady: false,
    list: '',
    queue: [],
}));

export const addToQueue = (fn: EventStoreQueueType) => {
    useEventStore.setState({ queue: [...useEventStore.getState().queue, fn] });
};

export const addFirstInQueue = (fn: EventStoreQueueType) => {
    useEventStore.setState({ queue: [fn, ...useEventStore.getState().queue] });
};

export const resetQueue = () => {
    useEventStore.setState({ queue: [] });
};

export const setList = (list: EventIdentifier) => {
    useEventStore.setState({ list: list });
};

export const setIsReady = (bool: boolean) => {
    useEventStore.setState({ isReady: bool });
};
