'use client';
import styled from '@emotion/styled';
import { darken, lighten } from 'color2k';
import { switchProp, ifProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';
import { ButtonProps } from './Button';

export const StyledResetButton = styled.button({
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    boxSizing: 'border-box',
    display: 'inline-flex',
    lineHeight: 1,
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    textRendering: 'inherit',
    textDecoration: 'none',
    webkitUserSelect: 'none',
    userSelect: 'none',
    webkitTapHighlightColor: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
});

export const StyledButton = styled(StyledResetButton)<ButtonProps>(
    ({ theme, color }) => ({
        // Reset
        ...theme.mixins.useTextStyle('button'),
        padding: '7px 16px',
        fontFamily: theme.fonts.regular,
        gap: '.75em', // multiple children
        transition: 'background-color 200ms ease-in',
        textTransform: 'uppercase',
        willChange: 'background-color',
        color: color ? `${color} !important` : theme.colors.white,
        minHeight: '48px',
        justifyContent: 'center',
        svg: {
            height: 25,
            width: 25,
            fill: 'currentcolor',

            '& path': {
                fill: 'currentcolor',
            },
        },

        borderRadius: theme.sizes.cornerRadiusLarge,
        '&:disabled': {
            backgroundColor: theme.colors.disabled,
            '&:hover': {
                cursor: 'not-allowed',
                backgroundColor: theme.colors.disabled,
            },
        },
    }),
    ifProp('small', ({ theme }) => ({
        minHeight: '32px',
        borderRadius: theme.sizes.cornerRadius,
        svg: {
            height: 16,
            width: 16,
        },
    })),
    switchProp('variant', {
        primary: ({ theme }) => ({
            backgroundColor: theme.colors.black,
            color: theme.colors.white,

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.black, 0.2),
            },

            '&:active,&:focus': {
                backgroundColor: lighten(theme.colors.black, 0.3),
            },
        }),
        brand: ({ theme }) => ({
            color: theme.colors.white,
            backgroundColor: theme.colors.primary,
            '&:hover': {
                backgroundColor: lighten(theme.colors.primary, 0.1),
            },

            '&:active, &:focus': {
                backgroundColor: lighten(theme.colors.primary, 0.2),
            },
        }),
        campaign: ({ theme }) => ({
            color: theme.colors.white,
            backgroundColor: theme.colors.campaign,
            '&:hover': {
                backgroundColor: lighten(theme.colors.campaign, 0.1),
            },

            '&:active, &:focus': {
                backgroundColor: lighten(theme.colors.campaign, 0.2),
            },
        }),
        secondary: ({ theme }) => ({
            backgroundColor: theme.colors.grey100,

            '&:hover': {
                backgroundColor: lighten(theme.colors.grey100, 0.1),
            },

            '&:active, &:focus': {
                backgroundColor: lighten(theme.colors.grey100, 0.2),
            },
        }),
        plain: ({ theme }) => ({
            color: theme.colors.black,
            padding: 0,
            textTransform: 'none',
            svg: {
                '& path': {
                    fill: 'unset',
                },
            },

            '&:disabled': {
                backgroundColor: theme.colors.white,
                '&:hover': {
                    cursor: 'not-allowed',
                    backgroundColor: theme.colors.white,
                },
            },
            [breakpoints.md]: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        }),
        link: ({ theme }) => ({
            color: theme.colors.black,
            padding: 0,
            textTransform: 'none',
            textDecoration: 'underline',
            '&:disabled': {
                backgroundColor: theme.colors.white,
                '&:hover': {
                    cursor: 'not-allowed',
                    backgroundColor: theme.colors.white,
                },
            },
            [breakpoints.md]: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        }),
        icon: ({ theme }) => ({
            borderRadius: 4,
            svg: {
                '& path': {
                    fill: 'unset',
                },
            },
            /**
             * svg color is set by `currentColor`
             */
            color: theme.colors.black,
            '&:hover': {
                backgroundColor: lighten(theme.colors.black, 0.8),
            },

            '&:active, &:focus': {
                backgroundColor: lighten(theme.colors.black, 0.5),
            },
        }),
        filter: ({ theme }) => ({
            ...theme.mixins.useTextStyle('filterButton'),
            borderRadius: 25,
            padding: '0 16px',
            paddingRight: '24px',
            minHeight: '35px',
            gap: '.5em',
            backgroundColor: theme.colors.black,
            boxShadow: theme.shadows.button,

            [breakpoints.sm]: {
                borderRadius: 25,
            },

            svg: {
                height: 24,
                width: 24,
            },
        }),
        plp: ({ theme }) => ({
            ...theme.mixins.useTextStyle('button'),
            backgroundColor: theme.colors.black,
            color: theme.colors.white,
            width: '100%',
            minHeight: '30px',
            padding: '8px 8px',
            [breakpoints.sm]: {
                padding: '14px 16px',
            },

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.black, 0.2),
            },
        }),
        'inverse-plp': ({ theme }) => ({
            border: `1px solid ${theme.colors.brownLight}`,
            backgroundColor: '',
            color: theme.colors.black,
            ...theme.mixins.useTextStyle('bodySmall'),
            textTransform: 'none',

            '&:hover': {
                backgroundColor: theme.colors.grey10,
            },

            '&:focus': {
                backgroundColor: theme.colors.brownUltraLight,
            },
        }),
        pdp: ({ theme }) => ({
            ...theme.mixins.useTextStyle('large'),
            backgroundColor: theme.colors.black,
            color: theme.colors.white,
            width: '100%',
            padding: '14px 8px',

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: lighten(theme.colors.black, 0.2),
            },
        }),
        'pdp-secondary': ({ theme }) => ({
            ...theme.mixins.useTextStyle('large'),
            backgroundColor: theme.colors.white,
            border: `1px solid ${theme.colors.black}`,
            color: theme.colors.black,
            width: '100%',
            padding: '14px 8px',

            '&:hover': {
                backgroundColor: darken(theme.colors.white, 0.1),
            },
        }),
        'pdp-square': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display3'),
            backgroundColor: theme.colors.white,
            border: `none`,
            color: theme.colors.black,
            //width: '20px',
            padding: '0 18px 5px 18px',
            '&:disabled': {
                color: theme.colors.disabled,
                backgroundColor: theme.colors.white,
                '&:hover': {
                    cursor: 'not-allowed',
                    backgroundColor: theme.colors.white,
                },
            },
        }),
        tag: ({ theme }) => ({
            fontWeight: theme.mixins.useFontWeight('Regular'),
            borderRadius: 25,
            padding: '10px 16px',
            minHeight: 40,
            textTransform: 'none',
            color: theme.colors.black,
            backgroundColor: theme.colors.brownLight,
            [breakpoints.sm]: {
                borderRadius: 25,
            },
        }),
        alternative: ({ theme, alternativeTextColor, alternativeBackgroundColor }) => ({
            color: alternativeTextColor || theme.colors.white,
            backgroundColor: alternativeBackgroundColor || theme.colors.black,

            '&:hover': {
                backgroundColor: lighten(alternativeBackgroundColor || theme.colors.black, 0.2),
            },

            '&:active,&:focus': {
                backgroundColor: lighten(alternativeBackgroundColor || theme.colors.black, 0.3),
            },
        }),
    }),
    switchProp('size', {
        small: ({ theme }) => ({
            ...theme.mixins.useTextStyle('small'),
        }),
        large: ({ theme }) => ({
            ...theme.mixins.useTextStyle('large'),
            padding: '12px 16px',
            width: '100%',
        }),
    }),
    ifProp('fullWidth', {
        width: '100%',
    }),
);

export const StyledButtonContent = styled.span({
    alignItems: 'center',
    display: 'inline-flex',
    gap: '0.75em', // multiple children
});

export const StyledIcon = styled.span<ButtonProps>(
    {
        padding: '1em 1.5em',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        transition: '0.2s ease-in-out',
        transformOrigin: 'center',
    },
    switchProp('variant', {
        primary: {
            paddingLeft: '1.2em',
            paddingRight: '1.2em',
            '&:first-of-type': {
                paddingRight: 0,
                marginRight: '-0.5em',
            },
            '&:last-of-type': {
                paddingLeft: 0,
                marginLeft: '-0.5em',
            },
            '&:first-of-type:last-of-type': {
                padding: '1em',
                marginLeft: 0,
                marginRight: 0,
            },
            path: {
                fill: 'currentColor',
            },
        },
        secondary: {
            paddingLeft: '1.2em',
            paddingRight: '1.2em',
            '&:first-of-type': {
                paddingRight: 0,
                marginRight: '-0.5em',
            },
            '&:last-of-type': {
                paddingLeft: 0,
                marginLeft: '-0.5em',
            },
            '&:first-of-type:last-of-type': {
                padding: '1em',
                marginLeft: 0,
                marginRight: 0,
            },
            path: {
                fill: 'currentColor',
            },
        },
        plain: {
            padding: '1em 0.5em',
            '&:first-of-type': {
                marginRight: 10,
            },
            '&:last-of-type': {
                marginLeft: 20,
            },
            '&:first-of-type:last-of-type': {
                padding: '1em',
                marginLeft: 0,
                marginRight: 0,
            },
        },
    }),
);
