import { Frame } from '~/shared/utils';

declare global {
    interface Window {
        raptor: {
            cookieId: string | undefined;
            initialize: (props: {
                customerID: string;
                productIdParamIndex: number;
                eventTypeParamIndex: number;
                include: string[];
            }) => void;
            push: (key: string, value?: string) => void;
        };
    }
}

export const useRaptorEvents = (frame?: Frame | null) => {
    const isTracking = () => window?.raptor && frame?.settings?.raptorCustomerId;

    const userChange = (customerNo?: string) => {
        if (!isTracking()) return;
        console.debug('pushToRaptor: setRuid:', customerNo);
        if (customerNo != null) {
            window.raptor.push?.('setRuid', customerNo);
        }
    };

    return { userChange };
};
