/*
 * This hook will return only the params that are also product attributes for the
 * passed in product object.
 */
export const useVariationParams = (product = {}) => {
    const { variationAttributes = [], variationValues = {} } = product;
    const variationParams = variationAttributes.map(({ id }) => id);

    // Using all the variation attribute id from the array generated above, get
    // the value if there is one from the location search params and add it to the
    // accumulator.
    const filteredVariationParams = variationParams.reduce((acc, key) => {
        let value = variationValues?.[key];
        return value ? { ...acc, [key]: value } : acc;
    }, {});

    return filteredVariationParams;
};
