/**
 * useProduct Hook
 *
 * This hook fetches product data and its associated variants, utilizing
 * React Query for caching and data fetching.
 *
 * Purpose and Caching Mechanism:
 * The hook prefetches variants to minimize load times when users switch between different variants.
 * This improves the user experience by making the transition between different variants seamless.
 * React Query's caching mechanism comes into play here: if a variant has been previously loaded,
 * React Query serves its cached version, eliminating the need for a new network request.
 *
 * Flow:
 * 1. The primary product data is fetched based on the `id` from the router query.
 * 2. If the primary product has associated variants, these are also fetched.
 * 3. Each fetched product (including variants) is cached using its unique id as the React Query cache key.
 */

import { useEffect, useMemo } from 'react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useFrame } from '~/shared/utils';
import { useCommerceAPI } from '~/features/commerce-api/contexts';
import { setProduct } from './useProductDetailsStore';
import { getProducts } from '../pdp-api';

export const useProduct = () => {
    // Initialize React Query and Next.js router
    const queryClient = useQueryClient();
    const router = useRouter();
    const { data: frame } = useFrame();
    const api = useCommerceAPI();
    const id = router.query.id as string;

    // Function to fetch products
    const fetchProducts = (ids: string | string[]) => {
        return getProducts(api, {
            ids: Array.isArray(ids) ? ids.join(',') : ids,
            allImages: true,
            currency: frame?.market?.currency?.currency,
        });
    };

    // Fetch primary product data
    const { data, isFetching, isLoading, error, isSuccess } = useQuery({
        queryKey: ['variant', id],
        queryFn: () => fetchProducts(id),
        placeholderData: keepPreviousData, // using keepPreviousData function
        enabled: !!id,
        staleTime: 5 * 60 * 1000, // Data is fresh for 5 minute
        gcTime: 10 * 60 * 1000, // Data is removed after 10 minutes
        refetchOnWindowFocus: true,
    });

    const product = data ? data?.data[0] : null;

    // Memoize variant IDs to optimize performance and only consider the first 20 variants
    const variantIds = useMemo(() => {
        if (product?.c_variants) {
            return product.c_variants.slice(0, 20).map((v) => v.productId); // Only take the first 20
        }
        return null;
    }, [product?.id]);

    useQuery({
        queryKey: ['variants-', variantIds?.join('-')],
        queryFn: async () => {
            if (!variantIds) {
                return;
            }
            const response = await fetchProducts(variantIds);
            if (Array.isArray(response?.data)) {
                // Cache each variant product
                response?.data.forEach((variant) => {
                    queryClient.setQueryData(['variant', variant.id], { data: [variant] });
                });
            }
            return response;
        },
        enabled: isSuccess && !!variantIds && !!product && !!product?.c_variants,
        staleTime: 5 * 60 * 1000, // Data is fresh for 5 minute
        gcTime: 10 * 60 * 1000, // Data is removed from cache after 10 minutes
        refetchOnWindowFocus: true,
    });

    // Update the local state when the primary product data is fetched
    useEffect(() => {
        if (product?.id) {
            setProduct(product);
        }
    }, [product?.id]);

    return { product, error, isLoading, isFetching };
};
