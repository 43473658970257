import { useCallback } from 'react';
import { useFrame } from '../utils';
import { useTranslation } from '../utils/translation';

export const useUtils = () => {
    const {
        data: { culture, marketSelector },
    } = useFrame();

    const { translate } = useTranslation();

    const convertPriceToLocale = useCallback(
        (price?: number | null | string) => {
            const number = Number(price || 0);
            const numWithZeroes = number.toLocaleString(culture?.split('-')[0], {
                useGrouping: false,
                minimumFractionDigits: 2,
            });

            return numWithZeroes;
        },
        [culture],
    );

    const getPriceLabel = useCallback(
        (price: number) =>
            price === 0 ? translate('checkout.freeShipping') : convertPriceToLocale(price),
        [convertPriceToLocale, translate],
    );

    const getCountryCode = useCallback(() => {
        const [market] = marketSelector?.markets || [];
        return market.label?.toUpperCase() ?? '';
    }, [marketSelector]);

    const getHostName = () => {
        return `${window.location.protocol}//${window.location.hostname}`;
    };

    return {
        convertPriceToLocale,
        getCountryCode,
        getPriceLabel,
        getHostName,
    };
};
