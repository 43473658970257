import create, { StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

export type LocalEventType = {
    latestVisitedProducts: string[];
    setLatestVisitedProduct: (productId: string) => void;
};

type LocalEventTypePersist = (
    config: StateCreator<LocalEventType>,
    options: PersistOptions<LocalEventType>
) => StateCreator<LocalEventType>;

export const useLocalEvents = create<LocalEventType>(
    (persist as LocalEventTypePersist)(
        (set, get) => ({
            latestVisitedProducts: [],
            setLatestVisitedProduct: (productId) =>
                set({ latestVisitedProducts: [...get().latestVisitedProducts, productId] }),
        }),
        {
            name: 'latest-visited-product',
            getStorage: () => sessionStorage,
        }
    )
);
