import React from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { useUtils } from '~/shared/hooks/useUtils';
import { useTranslation } from '~/shared/utils/translation';
import { getPrice } from '~/features/product-details-page/components/ProductSchema';
import { useFrame } from '~/shared/utils';

export const PriceWrap = styled.div<{ multiple: boolean }>(({ theme, multiple }) => ({
    display: 'flex',
    justifyContent: multiple ? 'space-between' : 'center',
    textAlign: multiple ? 'left' : 'center',
    margin: `${theme.space[3]} 0 0`,
}));

export enum PriceTypes {
    DEFAULT = 'default',
    SALE = 'sale',
    DISABLED = 'disabled',
}

export enum PromoTypes {
    MEMBER = 'memberprice',
    DISCOUNT = 'discountprice',
    CHRISTMAS = 'christmasprice',
}
export type TPrice = {
    type?: PriceTypes;
    value: string | number;
    label: string;
    schema?: boolean;
    promotype?: PromoTypes;
};

export interface ProductPrice {
    beforeValue?: number;
    value: string;
}

export const Price = styled.div<Pick<TPrice, 'type'>>(
    ({ theme }) => ({
        ...theme.mixins.useTextStyle('productPrice'),
        display: 'block',
    }),
    switchProp(
        'type',
        {
            default: ({ theme }) => ({
                color: theme.colors.black,
            }),
            sale: ({ theme }) => ({
                color: theme.colors.onSale,
                textAlign: 'right',
            }),
            disabled: ({ theme }) => ({
                color: theme.colors.grey50,
                textDecoration: 'line-through',
            }),
        },
        'default',
    ),
);

export const PriceLabel = styled.div<
    Pick<TPrice, 'type'> & { align?: 'left' | 'right' | 'center' }
>(
    ({ theme, align }) => ({
        ...theme.mixins.useTextStyle('productInfo'),
        color: theme.colors.black,
        display: 'block',
        textAlign: align,
    }),
    switchProp(
        'type',
        {
            sale: ({ theme }) => ({
                color: theme.colors.onSale,
            }),
            disabled: ({ theme }) => ({
                color: theme.colors.grey50,
                textDecoration: 'strikethrough',
            }),
        },
        'default',
    ),
);

export const Prices = ({
    prices,
    defaultLabel,
    isCampaignRedirect = false,
}: {
    defaultLabel?: string;
    prices: TPrice[];
    isCampaignRedirect?: boolean;
}) => {
    if (prices.length == 0) {
        return null;
    }

    const multiplePrices = prices.length > 1 && !isCampaignRedirect;
    const { convertPriceToLocale } = useUtils();
    const { data } = useFrame();
    const { translate } = useTranslation();
    const memberPrice = prices.filter((price) => price.label === 'Medlemspris');

    const schemaPrice = getPrice(prices);

    return (
        <PriceWrap multiple={multiplePrices}>
            <meta itemProp="priceCurrency" content={data.market.currency?.currency} />
            <meta itemProp="price" content={String(schemaPrice?.value)} />
            {prices.map(({ value, type = PriceTypes.DEFAULT, label }, index) => {
                return (
                    <div key={`${value + index.toString() + 1}`}>
                        <PriceLabel
                            align={
                                multiplePrices && index < prices.length - 1
                                    ? 'left'
                                    : !multiplePrices
                                      ? 'center'
                                      : 'right'
                            }
                            type={type}
                        >
                            {index === 0 && memberPrice.length > 0
                                ? translate('pdp.memberPrice')
                                : label ?? defaultLabel}
                        </PriceLabel>
                        <Price type={type}>{convertPriceToLocale(value)}</Price>
                    </div>
                );
            })}
        </PriceWrap>
    );
};

export const FromPrice = ({
    price,
    label,
    priceType,
}: {
    price?: number;
    label?: string;
    priceType?: PriceTypes;
}) => {
    const { convertPriceToLocale } = useUtils();
    const { data } = useFrame();

    if (!price) return null;

    return (
        <PriceWrap multiple={false}>
            <meta itemProp="priceCurrency" content={data.market.currency?.currency} />
            <meta itemProp="price" content={String(price)} />
            <div>
                <Price type={priceType || PriceTypes.DEFAULT}>
                    {label && label} {convertPriceToLocale(price)}
                </Price>
            </div>
        </PriceWrap>
    );
};
