import { ImageLoaderProps } from 'next/legacy/image';
import { useConfig } from '~/shared/hooks';

type Props = ImageLoaderProps;

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

export const cloudflareLoader = ({ src, width, quality }: Props) => {
    const {
        config: { NEXT_PUBLIC_CLOUDFLARE_LOADER_URL },
    } = useConfig();
    if (!NEXT_PUBLIC_CLOUDFLARE_LOADER_URL) {
        throw new Error('Cannot load images from Cloudflare without url');
    }
    const params = [`width=${width}`, 'format=auto'];

    params.push(`quality=${quality ?? '65'}`);

    const paramsString = params.join(',');

    return `${NEXT_PUBLIC_CLOUDFLARE_LOADER_URL}/${paramsString}/${normalizeSrc(src)}`;
};
