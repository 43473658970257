import { toast } from 'react-toastify';

export const notifyError = (error: Error, autoClose?: number) => {
    toast.error(error.message, {
        theme: 'colored',
        autoClose: autoClose,
        containerId: 'toastmaincontainer',
    });
};

export const notifyInfo = (message: string) => {
    toast.info(message, { theme: 'colored', containerId: 'toastmaincontainer' });
};
